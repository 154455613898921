import * as React from "react";
import { graphql, Link } from "gatsby";
import Header from "./../components/page-layout/header/Header";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SEO from "../components/SEO";

// markup
const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO noindex={true} />
      <div className="flex flex-col h-screen">
        <Header bgColor="gray-100" />
        <main
          className="
        flex
        items-center
        justify-center
        bg-gray-100
        flex-grow
      "
        >
          <div className="flex flex-col items-center">
            <h1 className="font-extrabold text-ol-gradient text-9xl">404</h1>

            <h6 className="text-2xl font-bold text-center text-gray-900 md:text-3xl">
              <span className="font-extrabold">Oops!</span>{" "}
              {t("common:page404.title")}
            </h6>

            <p className="mb-8 text-center text-gray-500 md:text-lg">
              {t("common:page404.description")}
            </p>

            <Link
              to="/"
              className="font-bold text-primary text-xl text-gray-900"
            >
              {t("common:page404.goHome")} →
            </Link>
          </div>
        </main>
      </div>
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
